.App {
  padding: 5px 20px 20px 20px;
}

.App-logo {
  height: 1vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #F0F0F0;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 0.3vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.textarea-label {
  padding-right: 0.25em;
  display: inline-block;
  vertical-align: top;
}

.my-genotype {
  padding-right: 0.25em;
  display: inline-block;
  vertical-align: middle;
}

.my-status-icon {
  padding-right: 0.25em;
  display: inline-block;
  vertical-align: middle;
}

.genome-sun {
  height: 2vmin;
  vertical-align: top;
  pointer-events: none;
}

.App-Title {
  text-align: left;
  font-size: 2em;
  margin-left: 6em;
}

.Event-Title {
  text-align: left;
  font-size: 3em;
  font-weight: bold;
  margin-left: 90px;
}

.Event-Subtitle {
  text-align: left;
  font-size: 2em;
  font-weight: bold;
  margin-left: 90px;
  margin-bottom: 15px;
}

.user-instruction {
  horiz-align: left;
  font-style: oblique;
  font-size: 0.9em;
}

.find-genome-table  {
  width: 792px;
  margin-bottom: 0.5em;
  font-size: 0.9em;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: fixed;
}

.find-genome-table .choose-file-td {
  font-style: oblique;
  padding: 0.35em;
  text-align: left;
  width: 400px;
}

.find-genome-table .then-td {
  font-style: oblique;
  padding-right: 2em;
  text-align: left;
  width: 60px;
}
.find-genome-table .ingest-td {
  font-style: oblique;
  padding: 0.35em;
  text-align: left;
  width: 300px;
}
#table-container::after, #table-container::before {
  display: flex;
  content: "";
  height: 2px;
  background: black;
  width: 100%;
}

.search-box {
  padding: 5px;
  border: 2px solid black;
}

.search-form {
  padding: 0px;
}

.search-error {
  color: red;
}

.deleter {
  color: red;
}

.button-panel {
  padding-bottom: 0px;
}

.lookup-lozenge {
  border-radius: 9px;
}

.success-message {
  color: green;
}

.error-message {
  color: red;
}

.snp-table {
  border-collapse: collapse;
}

.snp-table .gibble {
  text-decoration: underline;
}

.snp-table .snibble {
  text-decoration: underline;
}

.snp-table .tibble {
  text-decoration: underline;
}

.snp-table tr th {
  border-top: 2px solid black;
  border-bottom: 2px solid black;
}

.snp-table .table-row {
  border-bottom: 1px solid black;
}

.snp-table .table-cell-snp-name {
  font-weight: bold;
  text-align: center;
}

.snp-table .table-cell-variant-name {
  text-align: center;
}

.snp-table .table-cell-risk-allele {
  text-align: center;
}

.snp-table .table-cell-genotype {
  text-align: center;
}

.snp-table .left-aligned-paragraph {
  horiz-align: left;
}

.snp-table .variant-health-risks {
  font-weight: bold;
}

.rsId-table-container {
  width: 16em;
  padding: 10px 10px 10px 10px;
  margin-bottom: 1.5em;
}

.rsId-table-container-scroll {
  background-color: #F5F5F5;
  border-radius: 5px;
  width: 16rem;
  max-height: 12rem;
  overflow: scroll;
}

.rsId-table-container-scroll::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.rsId-table-container-scroll::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, .5);
  box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.rsId-table thead {
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  background-color: #F5F5F5;
}

.rsId-table {
}

.rsId-table th {
  border-bottom: 2px solid black;
}

.rsId-table-name-th, .rsId-table-name-td {
  font-family: monospace, monospace;
  text-align: right;
  padding: 5px;
}

.rsId-table-name-th {
  padding-right: 20px;
}

.rsId-table-genome-th, .rsId-table-genome-td {
  text-align: left;
  padding: 5px;
}

.footer {
  background-color: #F0F0F0;
  min-height: 1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 0.2vmin);
  text-align: left;
}
